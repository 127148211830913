<template>
  <div id='app'>
    <app-modalBody
      v-bind:versionId="versionId"
      v-bind:clientId="clientId"
      v-bind:engagementId="engagementId"
    ></app-modalBody>
  </div>
</template>

<script>
  import ModalBody from './modal-body.vue'

  export default {
    name: 'app',
    props: ['engagementId', 'clientId', 'versionId'],
    components: {
      'app-modalBody': ModalBody
    },
  }
</script>

import Vue from 'vue'
import App from './App.vue'
import singleSpaVue from 'single-spa-vue'

export default () => {
  return singleSpaVue({
    Vue,
    appOptions: {
      template: '<app v-bind:versionId=version v-bind:clientId=clientId v-bind:engagementId=engagementId />',
      components: { App }
    }
  })
}
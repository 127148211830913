// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
label.select-label {
  color: #52555a;
  font-size: 1.4rem;
  text-align: right;
  width: 112px;
}
.selecto {
  width: 289px;
}
.select-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/select-list.vue"],"names":[],"mappings":";AA8DA;EACA,cAAA;EACA,iBAAA;EACA,iBAAA;EACA,YAAA;AACA;AACA;EACA,YAAA;AACA;AAEA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;AACA","sourcesContent":["<template>\n  <div class=\"row\">\n    <div class=\"cps-form-group select-form\">\n      <label class=\"select-label\">{{label}}</label>\n      <select class=\"selecto cps-form-control\" v-on:change=\"setOption\" :disabled=\"list.length === 0\">\n        <option :value=\"0\">Select</option>\n        <option v-for=\"item in list\" :key=\"item.id\" :value=\"item.id\">\n          {{item.name}}\n        </option>\n      </select>\n    </div>\n  </div>\n</template>\n\n<script>\n  export default {\n    props: {\n      list: {\n        type: Array,\n        required: false\n      },\n      selected: {\n        type: String,\n        required: false\n      },\n      set: {\n        type: Function,\n        required: true\n      },\n      label: {\n        type: String,\n        required: true\n      }\n    },\n    data() { return {} },\n    methods: {\n      setOption: function(e){\n        e.target.value !== 0 && this.$emit('update', this.set(e.target.value))\n      }\n    }\n  }\n</script>\n\n<style>\n  label.select-label {\n    color: #52555a;\n    font-size: 1.4rem;\n    text-align: right;\n    width: 112px;\n  }\n  .selecto {\n    width: 289px;\n  }\n\n  .select-form {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    margin-bottom: 24px;\n  }\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

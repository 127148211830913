<template>
  <div>
    <div>
      <span class="cps-wt-bold">XML</span>
    </div>
    <div>
      <app-selectList
        v-bind:selected="selectedGovtCode"
        v-bind:list="govtCodes"
        v-bind:set="setGovtCode"
        label="Government code"
      ></app-selectList>
      <app-selectList
        v-bind:selected="selectedSubmissionType"
        v-bind:list="submissionTypes"
        v-bind:set="setSubmissionType"
        label="Submission type"
      ></app-selectList>
      <app-selectList
        v-bind:selected="selectedSchemaForm"
        v-bind:list="schemaForms"
        v-bind:set="setSchemaForm"
        label="Schema form"
      ></app-selectList>
    </div>
    <div>
      <button
        class="cps-btn +primary"
        v-on:click="startDownload"
        :disabled="selectedGovtCode == null || selectedSubmissionType == null"
      >
        Download
      </button>
    </div>
  </div>
</template>

<script>
  import SelectList from './select-list.vue';
  import { fetchAsObservable } from "fetcher!sofe";
  import { pluck, map, tap } from "rxjs/operators";

  export default {
    name: 'modal',
    props: ['engagementId', 'clientId', 'versionId'],
    data() {
      return {
        govtCodes: [],
        selectedGovtCode: null,
        submissionTypes: [],
        selectedSubmissionType: null,
        schemaForms: [],
        selectedSchemaForm: null,
        endpoint: null
      }
    },
    components: {
      'app-selectList': SelectList
    },
    methods: {
      fetchGovtCodes: function() {
        this.govtCodes$ = fetchAsObservable(
          `/wg/versions/${this.versionId}/government-codes`
        ).pipe(
          pluck('government-codes'),
          map( codes =>
            Object.keys(codes).reduce(
              (acc, curr) => [...acc, { id: curr, name: codes[curr] }],
              []
            )
          )
        )
        .subscribe( codes => this.govtCodes = codes )
      },
      setGovtCode: function(value) {
        this.selectedGovtCode = value
        this.fetchSubmissionTypes()
      },
      setSubmissionType: function(val) {
        this.selectedSubmissionType = val
        this.fetchSchemaForms()
        this.endpoint = `/wg/clients/${this.clientId}/engagements/${this.engagementId}/versions/${this.versionId}/government-codes/${this.selectedGovtCode}/submission-types/${this.selectedSubmissionType}/view-xml`;
      },
      setSchemaForm: function(val) {
        this.endpoint = this.endpoint.split('?')[0] + `?schema-forms=${val}`;
        this.selectedSchemaForm = val
      },
      fetchSubmissionTypes: function() {
        this.submissionTypes$ = fetchAsObservable(
          `/wg/versions/${this.versionId}/government-codes/${this.selectedGovtCode}/submission-types`
        ).pipe(
          pluck('submission-types')
        )
        .subscribe( submissionTypes => {
          this.submissionTypes = submissionTypes.map(
            type => ({id: type.submission_type, name: type.submission_type})
          )
          this.schemaForms = []
        })
      },
      fetchSchemaForms: function() {
        this.schemaForms$ = fetchAsObservable(
          `/wg/versions/${this.versionId}/government-codes/${this.selectedGovtCode}/submission-types/${this.selectedSubmissionType}/index-schema-forms`
        ).pipe(
          pluck('schema-forms'),
        )
        .subscribe( schemaForms => {
          this.schemaForms = schemaForms.map(
            form => ({...form, id: form.name})
          )
        })
      },
      startDownload: function(e) {
        e.stopPropagation()
        window.open(this.endpoint)
      }
    },
    created: function() {
      const vueModule = this
      vueModule.fetchGovtCodes()
    },
    beforeDestroy: function() {
      if (this.schemaForms$) this.schemaForms$.unsubscribe();
      if (this.submissionTypes$) this.submissionTypes$.unsubscribe();
      if (this.govtCodes$) this.govtCodes$.unsubscribe();
    }
  }
</script>

<style>
  .cps-modal__dialog {
    top: 30%;
    max-width: 482px;
  }
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      [
        _c("app-selectList", {
          attrs: {
            selected: _vm.selectedGovtCode,
            list: _vm.govtCodes,
            set: _vm.setGovtCode,
            label: "Government code",
          },
        }),
        _vm._v(" "),
        _c("app-selectList", {
          attrs: {
            selected: _vm.selectedSubmissionType,
            list: _vm.submissionTypes,
            set: _vm.setSubmissionType,
            label: "Submission type",
          },
        }),
        _vm._v(" "),
        _c("app-selectList", {
          attrs: {
            selected: _vm.selectedSchemaForm,
            list: _vm.schemaForms,
            set: _vm.setSchemaForm,
            label: "Schema form",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", [
      _c(
        "button",
        {
          staticClass: "cps-btn +primary",
          attrs: {
            disabled:
              _vm.selectedGovtCode == null ||
              _vm.selectedSubmissionType == null,
          },
          on: { click: _vm.startDownload },
        },
        [_vm._v("\n      Download\n    ")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("span", { staticClass: "cps-wt-bold" }, [_vm._v("XML")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "cps-form-group select-form" }, [
      _c("label", { staticClass: "select-label" }, [_vm._v(_vm._s(_vm.label))]),
      _vm._v(" "),
      _c(
        "select",
        {
          staticClass: "selecto cps-form-control",
          attrs: { disabled: _vm.list.length === 0 },
          on: { change: _vm.setOption },
        },
        [
          _c("option", { domProps: { value: 0 } }, [_vm._v("Select")]),
          _vm._v(" "),
          _vm._l(_vm.list, function (item) {
            return _c(
              "option",
              { key: item.id, domProps: { value: item.id } },
              [_vm._v("\n        " + _vm._s(item.name) + "\n      ")]
            )
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="row">
    <div class="cps-form-group select-form">
      <label class="select-label">{{label}}</label>
      <select class="selecto cps-form-control" v-on:change="setOption" :disabled="list.length === 0">
        <option :value="0">Select</option>
        <option v-for="item in list" :key="item.id" :value="item.id">
          {{item.name}}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      list: {
        type: Array,
        required: false
      },
      selected: {
        type: String,
        required: false
      },
      set: {
        type: Function,
        required: true
      },
      label: {
        type: String,
        required: true
      }
    },
    data() { return {} },
    methods: {
      setOption: function(e){
        e.target.value !== 0 && this.$emit('update', this.set(e.target.value))
      }
    }
  }
</script>

<style>
  label.select-label {
    color: #52555a;
    font-size: 1.4rem;
    text-align: right;
    width: 112px;
  }
  .selecto {
    width: 289px;
  }

  .select-form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }
</style>
